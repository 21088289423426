body {
  background: rgb(131,58,180);
  background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
}

.app {
  text-align: center;
  padding: 2em;
}

.app > canvas, #latest {
  background-color: white;
  border-radius: 0.5em;
  margin: 1em 0;
}

#latest {
  padding: 1em 1em 0.5em 1em;
}

#values {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

#values > div {
  font-size: 2em;
}

#timestamp {
  text-align: right;
  font-size: 0.8em;
}
